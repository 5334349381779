import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { selectEmailAddress, selectUserRedirectData } from "../../store/userSlice";
import { useHistory } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import { PrimaryTitles, SecondaryTitles } from "../../shared/TitleTexts";
import { AuthenticationTitle } from "../../shared/TitleTexts.js";

const Complimentary = () => {
    let history = useHistory();
    useEffect(() => {
        !email && history.push('/')
    })

    const redirectionData = useSelector(selectUserRedirectData);
    const email = useSelector(selectEmailAddress);
    return (
        <PageContainer
            formId={'complimentary'}
            primaryTitle={PrimaryTitles.SignIn}
            secondaryTitle={SecondaryTitles.SignIn}
            onSubmit={()=>{}}
            authenticationProgress={AuthenticationTitle.SignIn.progress}
            authenticationTitle={AuthenticationTitle.SignIn.title}
            content={
                <div className="complimentary-container">
                    <p>Hi {redirectionData?.firstName},</p>
                    <p>You have a complimentary subscription.</p>
                    <p>You can access all your plans and insights via the myDNA Unlocked app.</p>
                </div>
            }
        />
    )
}

export default Complimentary
