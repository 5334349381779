import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    SIGNED_IN: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = action.payload;
    },
    SIGN_IN_FAILURE: state => {
      state.user = null;
    },
    FORGOT_PASSWORD: (state, action) => {
      state.user = {emailAddress : action.payload};
    }
  },
});

export const { SIGNED_IN, SIGN_IN_FAILURE, FORGOT_PASSWORD } = userSlice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = state => state.user.user;
export const selectEmailAddress = state => state.user?.user?.emailAddress;
export const selectUserRedirectData = state => state.user?.user?.redirectionData;

export default userSlice.reducer;
